import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-lista-equipos',
  templateUrl: './lista-equipos.component.html',
  styleUrls: ['./lista-equipos.component.scss']
})
export class ListaEquiposComponent {
data:any

fuerza1:any
fuerza:any
  constructor(private router: Router, private service: GeneralService) { }

  ngOnInit() {

    this.service.listaEquiposFuerza(0).subscribe(res => {
      this.fuerza = res.body;


    })

    this.service.listaEquiposFuerza(1).subscribe(res => {
      this.fuerza1 = res.body;


    })



}

verEquipo(id:any){
  this.router.navigate(['admin/jugadoras/', id])
}


}
