
<label for="">1232</label>

<body class="body">




  <section class="photos-section py-5 d-flex justify-content-center" id="seccion" style="margin-top: 10VH;">



    <form [formGroup]="documentoForm">


      <div class="row" style="width: 100%;justify-content: center; text-align: center;">

    <div class="col-lg-5 col-md-6 d-flex align-items-stretch" >
      <label  style="color: aliceblue;font-weight: bolder;" for="">Equipo local</label>
      <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"  formControlName="LOCAL">
        <option *ngFor="let datos of data" value="{{this.datos.ID}}" >{{this.datos.NOMBRE}}</option>

      </select>
      <br>

    </div>

    <div style="text-align: center;justify-content: center;" class="col-lg-2 col-md-6 d-flex align-items-stretch" >
<label for="" style="text-align: center; font-weight: bolder; font-size: 3vh; color: aliceblue;">VS</label>

    </div>

<br>
    <div class="col-lg-5 col-md-6 d-flex align-items-stretch" >

      <label for="" style="color: aliceblue;font-weight: bolder;">Equipo Visitante</label>
      <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example"  formControlName="VISITANTE">
        <option *ngFor="let datos of data" value="{{this.datos.ID}}" >{{this.datos.NOMBRE}}</option>


      </select>



    </div>



      </div>


<div class="row" style="justify-content: center; text-align: center;">
<div class="col-12" style="justify-content: center; text-align: center;">
<button style="background-color: brown; color: aliceblue;border-color: brown; border-radius: 1vh; height: 5vh; width: 25vh; font-size:2vh;" (click)="crear()"> Crear Partido</button>

</div>

</div>





    </form>


      </section>






