<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>SAION - Tecnología gubernamental</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<div class="row">
  <div class="col-2"></div>
  <div class="col-8">
    <div class="row justify-content-center"
      style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753); width: 100%; margin-top: 10%;">
      <div class="col-12 col-md-8 col-lg-8 col-xl-6">
        <form [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value)">
          <div class="row">
            <div class="col text-center">
              <img src="https://i.imgur.com/hyUzSfA.png" height="100" width="100" alt=""
                style="margin-top: 1vh;font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;">
              <h1>Iniciar Sesión</h1>
              <p style="margin-top: 1vh;font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;"
                class="text-h3">Rellene los datos para iniciar sesión. </p>
            </div>
          </div>

          <div class="row align-items-center mt-4">
            <div class="col ">
              <input class="form-control" placeholder="Usuario" formControlName="usuario"
                style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;">
            </div>
          </div>
          <div class="row align-items-center mt-4">
            <div class="col">
              <input type="password" class="form-control" placeholder="Contraseña" formControlName="contrasena"
                id="password" type="password" class="form-control"
                style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;"
                name="contrasena">
            </div>

          </div>
          <div class="row mt-4" style="justify-content: center; display: flex;">
            <div class="row">
              <div class="col-12" style="justify-content: center; display: flex; color: #000000;">
                <div style="margin-bottom: 5vh;" *ngIf="iniciandoSesion === true" class="spinner-border"></div>
              </div>

              <div class="col-12">
                <button *ngIf="iniciandoSesion === false" type="submit" class="btn  mt-4 button"
                      style="width: 100%; margin-bottom: 5vh;font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;">Iniciar
                      Sesión</button>
                <label
                  style="color: #000000;; margin-left: 28%; margin-right: 20%; margin-bottom: 2vh; font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;"
                  *ngIf="this.login === false">Revise los
                  datos o su conexión</label>
              </div>
            </div>

          </div>

        </form>
      </div>
    </div>

  </div>

  <div class="col-2"></div>

</div>
