
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { IndexComponent } from '../index/index.component';
import { AuthEGuard } from 'src/app/core/guard/authE.guard';



import { ListaEquiposComponent } from '../lista-equipos/lista-equipos.component';
import { LoginComponent } from '../LOGIN/login/login.component';
import { ListaJugadorasComponent } from '../lista-jugadoras/lista-jugadoras.component';
import { NuevaComponent } from '../lista-jugadoras/nueva/nueva.component';
import { NuevoEquipoComponent } from '../lista-equipos/nuevo-equipo/nuevo-equipo.component';
import { ClasificacionComponent } from '../clasificacion/clasificacion.component';
import { PartidoComponent } from '../partido/partido.component';


const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/

  {
    path: 'index',
    component: IndexComponent,

  },

  {
    path: 'equipos',
    component: ListaEquiposComponent,

  },
  {

    path: 'login',
    component: LoginComponent,

  },
  {
    path: 'jugadoras/:id',
    component: ListaJugadorasComponent,

  },
  {
    path: 'jugadoras/nuevo/registro',
    component: NuevaComponent,

  },
  {
    path: 'nuevo/equipos',
    component: NuevoEquipoComponent,

  },
  {
    path: 'clasificacion',
    component: ClasificacionComponent,

  },
  {
    path: 'partido',
    component: PartidoComponent,

  }
];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }

