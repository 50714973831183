<body class="body">

  <div style="text-align: center; justify-content: center;margin-top: 1vh; ">
    <strong
      style="font-size: 4vh;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; color: #ffffff;">Liga
      Municipal de Voleibol</strong>
  </div>


  <section class="photos-section" style="overflow-y: scroll; overflow-x: scroll;" id="seccion">

    <div style="overflow-y: scroll hidden; overflow-x: scroll hidden; margin-top: 2vh;">

      <div class="row" style="justify-content: center;text-align: center;">

        <!---aqui-->

        <!-- empieza video -->


        <div class="col-lg-8 col-md-6 col-12 d-flex align-items-stretch" style="margin-top: 2vh;">
          <div
            style=" border-radius: 2vh; box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.15);justify-content: center;width:1200px;height: 800px; background-color: #ffffff00;">
            <video #media id="my_video_1" style="width: 100%; border-radius: 2vh;" width="100%" height="100%" controls
              [src]="currentVideo.src" preload="auto" autoplay crossorigin>
            </video>




          </div>


        </div>



        <div class="col-4 d-none d-sm-none d-md-block">





          <div class="row" style="margin-top: 5vh;">

            <div class="col-lg-5 col-md-12 col-12" style="justify-content: end;text-align: end; ">

              <label for="" style="font-size: 3.5vh;font-weight: bolder;color: #ffffff;"
                (click)="restarL()">{{this.local[0].NOMBRE}}</label>
              <img height="90%" width="100%" [src]="this.local[0].ESCUDO" (click)="sumarL()" style="border-radius: 2vh;"
                alt=""><br>

            </div>

            <div class="col-lg-2 col-md-12 col-12 d-flex align-items-stretch"
              style="justify-content: center; text-align: center;"><label for="" style="color: #ffffff;"> VS</label>
              <label for="" style="font-size: 3vh;font-weight: bolder;margin-top: 7vh;"></label>
            </div>



            <div class="col-lg-5 col-md-12 col-12 " style="text-align: start; justify-content: start;">
              <label for="" style="font-size: 3.5vh;font-weight: bolder;color: #ffffff;"
                (click)="restarV()">{{this.visita[0].NOMBRE}}</label>
              <img height="90%" width="100%" [src]="this.visita[0].ESCUDO" (click)="sumarV()"
                style="border-radius: 2vh;" alt=""> <br>

            </div>


          </div>


          <div class="row" style="margin-top: 2vh;">



            <div *ngIf="this.token==true" class="col-lg-4 col-md-12 col-12 d-flex align-items-stretch"
              style="margin-top: 8vh;">

              <div
                style=" border-radius: 2vh; box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.15);justify-content: center;width:800px;height: 450px; background-color: #ffffff;">


                <div class="row">


                  <div class="col-lg-12 col-md-12 col-12">

                    <button
                      style="background-color: #004c82;border-color: #004c82; color: #ffffff;border-radius: 5px;margin-top: 1vh;width:  90%;"
                      (click)="terminarP()"> Terminar Partido</button>


                    <button
                      style="background-color: #004c82;border-color: #004c82; color: #ffffff;border-radius: 5px;margin-top: 1vh;width:  90%;"
                      (click)="cambiarSet()"> Siguiente Set</button>



                    <button
                      style="background-color: #004c82;border-color: #004c82; color: #ffffff;border-radius: 5px;margin-top: 1vh;width:  90%;"
                      (click)="reiniciarM()"> Reiniciar Marcador</button>



                    <button
                      style="background-color: #004c82;border-color: #004c82; color: #ffffff;border-radius: 5px;margin-top: 1vh; width: 90%;"
                      (click)='startTimerL()'> Tiempo de descanso {{this.local[0].NOMBRE}}</button>
                    <button
                      style="background-color: #004c82;border-color: #004c82; color: #ffffff;border-radius: 5px; margin-top: 1vh;width: 90%;"
                      (click)='startTimerV()'> Tiempo de descanso {{this.visita[0].NOMBRE}}</button>
                    <button
                      style="background-color: #07bccc;border-color: #07bccc; color: #ffffff;border-radius: 5px;margin-top: 1vh; width: 90%;"
                      data-bs-toggle="modal" data-bs-target="#exampleModal">Panel de control</button>

                  </div>

                </div>

              </div>

            </div>


            <div class="col-lg-8 col-md-12 col-12 d-flex align-items-stretch">

              <div class="row" style="justify-content: center;text-align: center;">
                <div class="col-12">

                  <label for="" style="font-size: 5vh;font-weight: bolder;margin-top: 5vh;color: #ffffff;">Set
                    {{this.setMar}}</label>

                </div>

                <div class="col-12">

                  <label for=""
                    style="font-size: 5vh;font-weight: bolder;margin-top: 5vh;color: #ffffff;">{{this.mostrarSetL}} -
                    {{this.mostrarSetV}}</label>

                </div>

                <div class="col-lg-5 col-md-6 col-5 "
                  style="justify-content: end;text-align: end; font-size: 13vh; font-weight: bolder; color: #ffffff;">
                  {{this.puntosMarL}}
                </div>
                <div class="col-lg-2 col-md-6 col-2"
                  style="font-size: 3vh;font-weight: bolder;margin-top: 2vh; text-align: center;margin-top: 8vh;color: #ffffff;">
                  -</div>
                <div class="col-lg-5 col-md-6 col-5"
                  style="text-align: start; justify-content: start; font-size: 13vh;  font-weight: bolder;color: #ffffff;">
                  {{this.puntosMarV}}</div>


                <div class="col-6">

                  <label for="" style="font-size: 5vh;font-weight: bolder;color: #ffffff;">L</label>

                </div>

                <div class="col-6">

                  <label for="" style="font-size: 5vh;font-weight: bolder;color: #ffffff;">V</label>

                </div>

              </div>


            </div>

          </div>

        </div>


        <!-- termina video -->


        <br>




      </div>

    </div>
  </section>


  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    style="height: 80%;margin-top: 10vh">
    <div class="modal-dialog">
      <div class="modal-content" style="width: 100%;">


        <div class="modal-header">

          <a href="">Lista de Reproducción</a>
          <button style="background-color: #ffffff;border-color: #ffffff;border: solid;" (click)="regresarVideo()"><img
              width="50" height="50" src="https://img.icons8.com/glyph-neue/64/previous.png" alt="previous" /></button>

          <button style="background-color: #ffffff;border-color: #ffffff;border: solid;" (click)="video1()"><img
              width="50" height="50" src="https://img.icons8.com/glyph-neue/64/next.png" alt="next" /> </button>


          <button type="button" style="background-color: #ffffff;border-color: #ffffff;border: solid;" class="btn-close"
            data-bs-dismiss="modal" aria-label="Close"></button>
        </div>



        <div class="modal-body">





          <div class="card mb-3" style="height: 150px;" *ngFor="let data of videoItems">
            <div class="row g-0">
              <div class="col-md-4">
                <img [src]="data.Image"  class="img-fluid rounded-start" style="border-radius: 1vh;height: 140px; width: 160px;" alt="...">
              </div>
              <div class="col-8 d-none d-sm-none d-md-block">
                <div class="card-body">
                  <h5 class="card-title"
                    style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">
                    {{data.name}}</h5>
                  <p class="card-text"><button
                      style="background-color: #004c82;border-color: #004c82; color: #ffffff;border-radius: 5px;margin-top: 1vh; width: 90%;"
                      (click)='listaRepro(data.number)'>Reproducir Video</button></p>
                </div>
              </div>
            </div>
          </div>




        </div>






            <div class="modal-footer">
            </div>






      </div>

    </div>
  </div>





