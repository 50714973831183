import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
declare const menu: any;

@Component({
  selector: 'app-sidenav-admin',
  templateUrl: './sidenav-admin.component.html',
  styleUrls: ['./sidenav-admin.component.scss']
})
export class SidenavAdminComponent implements OnInit {
  token = false;
  rol: any;

  usuario: any;
  idUsuario: any;
  contrasena: any;
  correo: any;
  id: any;
  imagen: any;
  nombre: any;
  telefono: any;


  modulo: any;
  dispositivos: any;
  administrador: any;
  atencion: any;
  encuestas: any;
  comunicacion: any;
  tramites: any;
  pagos: any;
  oficialia: any;
  registros: any;
  caja: any;
  licencias: any;
  desarrolloUrbano: any;
  Cementerio: any;
  parquimetros: any;
  agua: any;
  vialidad: any;
  recibos: any;
  predial: any;
  tramitesMenu: any;
  recursosHumanos: any;
  guarderia: any;
  registroCivil: any;
  contratos: any;
  electricidad: any;
  catastro: any;
  archivo: any;

  reportes: any;
  buzon: any;

  //badge
  encuestasLenght: any;
  encuestasBadge: any;
  reportesBadge: any;
  buzonBadge: any;

  constructor(private router: Router

  ) { }

  ngOnInit() {


    if (localStorage.getItem('token')) {
      this.token = true;
    } else {
      this.token = false;
      this.router.navigate(['admin/login']);
    }

    this.rol = localStorage.getItem('rol');
  }

cerrar(){
  localStorage.clear();
  location.reload();
}


}


