

<section style=" height: 6vh; width: 100%;" ></section>



<div class="row">

<div class="col-6">

  <div class="wrapper">
    <section style=" padding-left: 2vh; padding-right: 2vh; overflow: scroll;">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane show active" id="activos" role="tabpanel">
          <div class="row">
            <div style="justify-content: left; display: flex; width: 100%;" class="col-sm-2">
              <!-- <button (click)="reporte()" class="boton" style="background-color: #084f7e; color: #F3F3F4; border-color: #084f7e; border-radius: 1vh;">Descargar tabla Primera Fuerza</button> -->
            </div>

          </div>
          <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
            <thead style="color: #084f7e;">

              <th scope="col">EQUIPO</th>
              <th scope="col">PUNTOS</th>
              <th scope="col">VICTORIAS</th>
              <th scope="col">DERROTAS</th>


            </thead>
            <tbody>
              <tr id="tabla"
                *ngFor="let dato of fuerza | paginate: { itemsPerPage: 15, currentPage: pages, id:'activos' }; let i = index" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">

                <td>{{dato.NOMBRE}}</td>
                <td>{{dato.PUNTOS}}</td>
                <td>{{dato.VICTORIAS}}</td>
                <td>{{dato.DERROTAS}}</td>



              </tr>

            </tbody>

          </table>
          <div class="d-flex justify-content-center">
            <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
              nextLabel="Siguiente"></pagination-controls>
          </div>
        </div>

      </div>

    </section>
  </div>

</div>




<div class="col-6">

  <div class="wrapper">
    <section style="background-color: white; padding-left: 2vh; padding-right: 2vh; overflow: scroll;">
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane show active" id="activos" role="tabpanel">
          <div class="row">
            <div style="justify-content: left; display: flex; width: 100%;" class="col-sm-2">
              <!-- <button (click)="reporte()" class="boton" style="background-color: #ff05a3; color: #F3F3F4; border-color: #ff05a3; border-radius: 1vh;">Descargar tabla Segunda Fuerza</button> -->
            </div>

          </div>
          <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
            <thead style="color: #ff05a3;">

              <th scope="col">EQUIPO</th>
              <th scope="col">PUNTOS</th>
              <th scope="col">VICTORIAS</th>
              <th scope="col">DERROTAS</th>


            </thead>
            <tbody>
              <tr id="tabla2"
                *ngFor="let dato of fuerza1 | paginate: { itemsPerPage: 15, currentPage: pages, id:'activos' }; let i = index" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">

                <td>{{dato.NOMBRE}}</td>
                <td>{{dato.PUNTOS}}</td>
                <td>{{dato.VICTORIAS}}</td>
                <td>{{dato.DERROTAS}}</td>



              </tr>

            </tbody>

          </table>
          <div class="d-flex justify-content-center">
            <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
              nextLabel="Siguiente"></pagination-controls>
          </div>
        </div>

      </div>

    </section>
  </div>


</div>

</div>




