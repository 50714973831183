import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-nueva',
  templateUrl: './nueva.component.html',
  styleUrls: ['./nueva.component.scss']
})
export class NuevaComponent {
dato:any
  documentoForm = new FormGroup({
    NOMBRE: new FormControl(''),
    DORSAL: new FormControl(''),
    EQUIPO: new FormControl(''),
    FOTO: new FormControl(''),
    ASISTENCIA: new FormControl(''),
  })
  documentForm2 = new FormGroup({
    JUGADORA: new FormControl('', Validators.required),
    NOMBRE: new FormControl('', Validators.required),
    FOTO: new FormControl('', Validators.required),
    FECHA:new FormControl('', Validators.required),
  });

  //Variables para url de archivos
  urlDocumento = '';

  //Variables para indicar carga de archivos
  subiendoDocumento = false;
data:any
  docs: any;
  fecha: any;
  fdia: any;
  fmes: any;
  fanio: any;
  idNoticia: any;
id:any
  constructor(private api: GeneralService, private alerts: SweetAlertService, private storage: Storage, private router: Router) { }

  ngOnInit(): void {
    this.api.listaEquipos().subscribe(res => {
      this.data = res.body;
      console.log(this.data);

    })
  }



  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })


  async changeFileMenu(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);
this.dato=file;
          this.documentoForm.value.FOTO = file;
          console.log(this.documentoForm.value.FOTO);

        }).catch(error => { console.log(error) });

      })
    })

  }



  cargar() {

this.documentoForm.value.ASISTENCIA='0'
    console.log(this.documentoForm.value);
    if (
      this.documentoForm.value.NOMBRE !== '' &&

      this.documentoForm.value.EQUIPO !== '') {
      console.log(this.documentoForm.value);

      this.api.nuevaJugadora(this.documentoForm.value).subscribe(res => {
         this.id = res.body.insertId;
this.asistencia()
        this.alerts.alertaRealizadoAsistencia('Completado', 'La jugadora ha sido registrada');

      })
    } else {
      this.alerts.alertaError('Error', 'Llene los campos necesarios');
    }
  }


  asistencia() {
    this.documentForm2.value.JUGADORA=this.id
    this.documentForm2.value.NOMBRE=this.documentoForm.value.NOMBRE
    this.documentForm2.value.FOTO=this.documentoForm.value.FOTO
    this.documentForm2.value.FECHA='2024-09-05'

       console.log('entra');


           this.api.asistencia(this.documentForm2.value).subscribe(res => {
            console.log('hecho');
           })
           location.reload()
         }
       }





