<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<header id="header" class="fixed-top">

  <div class="container d-flex align-items-center">




    <h1 class="logo me-auto"><a href="inicio"><span></span></a></h1>

    <nav id="navbar" class="navbar order-last order-lg-0" style="text-align: start;justify-content: start;">


<label for="" style="text-align: start;justify-content: start;color: rgb(3, 50, 69);font-size: 5vh;font-weight: bolder;">MUÉVANLAS </label>


      <ul>



        <li><a href="admin/index" class="active">Marcador</a></li>

        <li><a href="admin/clasificacion" >Clasificación</a></li>

        <li><a href="admin/equipos">Equipos</a>



        <li *ngIf="this.token==true" class="dropdown"><a><span>Liga</span> <i class="bi bi-chevron-down"></i></a>
          <ul>

            <li><a href="admin/jugadoras/nuevo/registro">Registrar Jugadora</a>
            <li><a href="admin/nuevo/equipos">Registrar Equipo</a>

            <li><a href="admin/partido">Nuevo Partido</a>
            </li>
          </ul>
        </li>
        <a *ngIf="this.token==false" style="color: aliceblue;" href="/admin/login" class="youtube">
          <img width="30" height="30" src="https://img.icons8.com/cute-clipart/64/login-rounded-right.png" alt="login-rounded-right"/></a>


          <a *ngIf="this.token==true" style="color: aliceblue;" href="/admin/login" class="youtube">
            <img width="30" height="30" src="https://img.icons8.com/fluency/48/instagram-check-mark.png" alt="instagram-check-mark"/></a>


<a *ngIf="this.token==true" href="" (click)="cerrar()">  Cerrar Sesion</a>








      </ul>


      <button class="bi bi-list mobile-nav-toggle" style="background: none; border: none;" data-bs-toggle="collapse"
        data-bs-parent="#accordion" href="#collapse1">
      </button>
    </nav>

  </div>

  <nav id="collapse1" class="panel-collapse collapse in mobile-nav-toggle" data-bs-parent="#accordion">
    <div class="offcanvas-body">

      <div id="sidebar" class="text-ceter" style="width: 90%;">
        <div class="nav flex-column py-3">

          <ul class="list-unstyled ps-0">
            <ul class="list-unstyled ps-0">
              <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/index" class="active">Marcador</a>
              </li>
              <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/clasificacion">Clasificación</a></li>

              <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/equipos">Equipos</a></li>


              <li *ngIf="this.token==true" class="mb-1" class="btn align-items-center rounded ms-auto w-100">
                <button id="usuarios" href="#transparencia" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                  style="background: none; border: none;">
                  Liga <i class="bi bi-chevron-down"></i>
                </button>
                <div class="collapse animate__animated animate__zoomIn" id="transparencia" data-bs-parent="#sidebar">
                  <ul class="btn align-items-center rounded ms-auto w-100">
                    <li class="btn align-items-center rounded ms-auto w-100"><a
                        href="admin/jugadoras/nuevo/registro">Registrar Jugadora </a></li>
                    <li class="btn align-items-center rounded ms-auto w-100"><a
                        href="admin/nuevo/equipos">Registrar Equipo</a></li>
                    <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/partido">Nuevo Partido</a>
                    </li>
                  </ul>
                </div>
              </li>



            </ul>
          </ul>
        </div>

      </div>
    </div>





  </nav>

</header><!-- End Header -->
