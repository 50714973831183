


<body class="body">


  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
    />
  </head>



  <section class="photos-section "  id="seccion" style="margin-top: 10VH; background-image: url('https://i.imgur.com/VBfkKwh.png'); justify-content: center;text-align: center;">


    <h3 style="color: aliceblue;">Liga de Voleibol Municipal de Autlán</h3>
    <div class="row">
      <div class="col-6 d-none d-sm-none d-md-block">
    <img src="https://i.imgur.com/ZtBMNd5.jpeg" style="width: 25vh; height: 10vh;" alt="">
      </div>
      <div class="col-6 d-none d-sm-none d-md-block">
            <img src="https://i.imgur.com/AYyBGwT.png" style="width: 25vh; height: 10vh;" alt="">
      </div>
    </div>



  <div class="row" style="justify-content: center;text-align: center;margin-top: 2vh;">


<div class="col-6">
  <h3 style="color: aliceblue;">Equipos de Primera Fuerza</h3>
  <div class="card mb-3" style="width: 90%;margin-left: 5VH;height: 150px;" *ngFor="let data of fuerza" (click)="verEquipo(data.ID)">
    <div class="row g-0">
      <div class="col-md-4">
        <img [src]="data.ESCUDO"  class="img-fluid rounded-start" style="border-radius: 1vh;height: 140px;" alt="...">
      </div>
      <div class="col-8 d-none d-sm-none d-md-block">
        <div class="card-body">
          <h5 class="card-title" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">{{data.NOMBRE}}</h5>
          <p class="card-text"><label for="" style="color: rgb(5, 56, 138);font-weight: bolder;">Puntos: {{data.PUNTOS}}</label> / <label for="" style="color: #078e34;font-weight: bolder;">Victorias: {{data.VICTORIAS}}</label> /
           <label for="" style="color: #aa0b13;font-weight: bolder;">Derrotas: {{data.DERROTAS}}</label></p>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-6">
  <h3 style="color: aliceblue;">Equipos de Segunda Fuerza</h3>
  <div class="card mb-3" style="width: 90%;margin-left: 5VH;height: 150px;" *ngFor="let data of fuerza1" (click)="verEquipo(data.ID)">
    <div class="row g-0">
      <div class="col-md-4">
        <img [src]="data.ESCUDO" class="img-fluid rounded-start"style="border-radius: 1vh;height: 140px;" alt="...">
      </div>
      <div class="col-8 d-none d-sm-none d-md-block">
        <div class="card-body ">
          <h5 class="card-title" style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">{{data.NOMBRE}}</h5>
          <p class="card-text"><label for="" style="color: rgb(5, 56, 138);font-weight: bolder;">Puntos: {{data.PUNTOS}}</label> / <label for="" style="color: #078e34;font-weight: bolder;">Victorias: {{data.VICTORIAS}}</label> /
            <label for="" style="color: #aa0b13;font-weight: bolder;">Derrotas: {{data.DERROTAS}}</label></p>

        </div>
      </div>
    </div>
  </div>
</div>

  </div>










<!--
<div>



      <div class="row  animate__animated animate__fadeInDown" style="width: 100%;justify-content: center;text-align: center; margin-top: 2h;">



<div class="col-lg-3 col-md-12 col-12 d-flex align-items-stretch" style="justify-content: center;">
  <ul class="list-group" style="width: 100%;margin-top: 2vh;margin-left: 2vh;">

    <label for="" style="background-color: rgb(3, 57, 107); color: aliceblue; border-top-right-radius: 2vh;">Equipos Primera Fuerza</label>
    <li  *ngFor="let datos of fuerza" (click)="verEquipo(datos.ID)" class="list-group-item"> {{datos.NOMBRE}} </li>

  </ul>


  <ul class="list-group" style="width: 100%;margin-top: 2vh;margin-left: 2vh;">

    <label for="" style="background-color: rgb(243, 11, 158); color: aliceblue; border-top-right-radius: 2vh;">Equipos Segunda Fuerza</label>
    <li  *ngFor="let datos of fuerza1" (click)="verEquipo(datos.ID)" class="list-group-item"> {{datos.NOMBRE}} </li>

  </ul>


</div>




<br>





<div class="col-lg-7 col-md-12 col-12 d-flex align-items-stretch" style="overflow-y: scroll; overflow-x: scroll;">


<div  style="justify-content: center;margin-top: 2vh; ">
  <img style="border-radius: 2vh;" height="800" width="1000" src="https://i.imgur.com/0oTFt1O.png" alt="">
</div>

</div>

      </div>



    </div> -->

      </section>






